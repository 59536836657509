const realestateTransaction = {
    label1: {
        label: 'Tell us about your transaction',
        preFormOnly: true,
        fieldType: 'label',
    },
    transactionType: {
        label: 'What kind of real-estate transaction are you looking for?',
        fieldType: 'select',
        options: [
            {
                name: 'buyer',
                label: 'Buying',
            },
            {
                name: 'seller',
                label: 'Selling',
            },
            {
                name: 'ownerRefinancing',
                label: 'Owner Refinancing',
            },
            {
                name: 'mortageFinancing',
                label: 'Someone looking for mortgage financing',
            },
            {
                name: 'other',
                label: 'Other',
            },
        ],
        preFormOnly: true,
    },
    otherTransactionType: {
        label: 'Specify',
        dependsOn: {
            fieldName: 'transactionType',
            toBe: 'other',
        },
        preFormOnly: true,
    },
    numProperties: {
        label: 'How many properties are involved in this transaction?',
        fieldType: 'select',
        options: [
            {
                name: '1',
                label: '1',
            },
            {
                name: '2',
                label: '2',
            },
            {
                name: '3',
                label: '3',
            },
            {
                name: '4+',
                label: '4 or more',
            },
        ],
        preFormOnly: true,
    },
    propertyType: {
        label: 'What is/are the type of property(ies)?',
        fieldType: 'checkBoxes',
        options: [
            {
                name: 'detached-freehold',
                label: 'Detached or Freehold',
            },
            {
                name: 'condo',
                label: 'Condo',
            },
            {
                name: 'semi-detached',
                label: 'Semi-detached',
            },
            {
                name: 'mixed',
                label: 'Mixed',
            },
        ],
        preFormOnly: true,
    },
    specifyMixed: {
        label: 'Specify, mixed',
        dependsOn: {
            fieldName: 'propertyType',
            toBe: 'mixed',
        },
        preFormOnly: true,
    },

    propertyValue: {
        label: 'What is the value of the property or properties',
        preFormOnly: true,
    },
    mortgageCommitment: {
        label: 'Do you have a mortgage commitment from a financial lender?',
        fieldType: 'yesNo',
        preFormOnly: true,
    },
    mortgageAmount: {
        label: 'What is the amount of the mortgage?',
        dependsOn: {
            fieldName: 'mortgageCommitment',
            toBe: 'yes',
        },
        preFormOnly: true,
    },
    agreement: {
        label: 'If this is a purchase or a sale, did you sign an Agreement of purchase/sale?',
        fieldType: 'yesNo',
        preFormOnly: true,
    },
    closingDate: {
        label: 'What is the closing date?',
        fieldType: 'date',
        preFormOnly: true,
    },
    otherDetails: {
        label: 'Do you have any other details you would like to share?',
        preFormOnly: true,
    },
}

export default realestateTransaction
