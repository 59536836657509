// const apiUrl =
//     process.env.GATSBY_JURIGO_ENV === 'staging'
//         ? 'api-qa.jurigo.ca'
//         : process.env.GATSBY_JURIGO_ENV === 'production'
//         ? 'api.jurigo.ca'
//         : 'localhost:8000/api'

function apiUrl() {
    let x = ''
    try {
        x =
            process.env.GATSBY_JURIGO_ENV === 'staging'
                ? 'api-qa.jurigo.ca'
                : process.env.GATSBY_JURIGO_ENV === 'production'
                ? 'api.jurigo.ca'
                : 'localhost:8000/api'
    } catch (e) {}
    return x
}

export default apiUrl()
