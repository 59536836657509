import React from 'react'
import styled from '@emotion/styled'
import { Grid, Button } from '@material-ui/core'
import DatePicker from '../../components/DatePicker'

const LeftSpan = styled.span`
    display: block;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
`

const CriminalCase = ({
    saveFieldState,
    hasCourtDate,
    courtDate,
    hasCriminalFile,
    employementPeril,
}) => {
    const handleDateChange = (date) => {
        saveFieldState('courtDate', date, true)
    }

    return (
        <div style={{ width: '100%' }}>
            <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={0}
                            style={{ marginBottom: '20px' }}>
                            <LeftSpan>Avez-vous une date de cour?</LeftSpan>
                            <Grid item xs={3}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'hasCourtDate',
                                            'yes',
                                            true
                                        )
                                    }
                                    color={
                                        hasCourtDate?.value === 'yes'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Oui
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'hasCourtDate',
                                            'no',
                                            true
                                        )
                                    }
                                    color={
                                        hasCourtDate?.value === 'no'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Non
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'hasCourtDate',
                                            'dontknow',
                                            true
                                        )
                                    }
                                    color={
                                        hasCourtDate?.value === 'dontknow'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Je ne sais pas
                                </Button>
                            </Grid>
                            {hasCourtDate?.value === 'yes' && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        marginTop: '15px',
                                        textAlign: 'left',
                                    }}>
                                    <DatePicker
                                        label="Date de cour"
                                        name="courtDate"
                                        value={courtDate?.value}
                                        onChange={(date) =>
                                            handleDateChange(date)
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            style={{ marginBottom: '20px' }}>
                            <LeftSpan>
                                Avez-vous déjà un dossier criminel?
                            </LeftSpan>
                            <Grid item xs={3}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'hasCriminalFile',
                                            'yes',
                                            true
                                        )
                                    }
                                    color={
                                        hasCriminalFile?.value === 'yes'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Oui
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'hasCriminalFile',
                                            'no',
                                            true
                                        )
                                    }
                                    color={
                                        hasCriminalFile?.value === 'no'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Non
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'hasCriminalFile',
                                            'dontknow',
                                            true
                                        )
                                    }
                                    color={
                                        hasCriminalFile?.value === 'dontknow'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Je ne sais pas
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            style={{ marginBottom: '20px' }}>
                            <LeftSpan>
                                Est-ce qu’être reconnu coupable de l’infraction
                                pourrait mettre en péril votre emploi?
                            </LeftSpan>
                            <Grid item xs={3}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'employementPeril',
                                            'yes',
                                            true
                                        )
                                    }
                                    color={
                                        employementPeril?.value === 'yes'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Oui
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'employementPeril',
                                            'no',
                                            true
                                        )
                                    }
                                    color={
                                        employementPeril?.value === 'no'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Non
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() =>
                                        saveFieldState(
                                            'employementPeril',
                                            'dontknow',
                                            true
                                        )
                                    }
                                    color={
                                        employementPeril?.value === 'dontknow'
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    variant="contained">
                                    Je ne sais pas
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default CriminalCase
