import React from 'react'
import styled from '@emotion/styled'

const Error = styled.span`
    color: rgb(244, 67, 54);
    font-size: 10px;
`
export default class ValidityWarning extends React.Component {
    constructor() {
        super()
        this.state = {
            active: '',
        }
    }
    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                active: 'active',
            })
        }, 32)
    }
    render() {
        const { active } = this.state
        const className = this.props.left
            ? `notValid ${active} left}`
            : `notValid ${active}`
        return (
            <Error>
                {this.props.validityMessage || 'Ce champs est requis.'}
            </Error>
        )
    }
}
