import decode from 'jwt-decode'
import apiUrl from '../utils/apiUrl'

function saveSession(tokenString) {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return null
        }
        const token = decode(tokenString)
        window.localStorage.setItem('user', token.username)
        window.localStorage.setItem('role', token.role)
        window.localStorage.setItem('id', token.id)
        window.localStorage.setItem('expiry', token.exp * 1000)
        window.localStorage.setItem('token', tokenString)
        window.localStorage.setItem('jti', token.jti)
        return token
    } catch (err) {
        return null
    }
}

function deleteSession() {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return
        }
        const session = getSession()
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('role')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('id')
        window.localStorage.removeItem('jti')
        window.localStorage.removeItem('expiry')

        fetch(`//${apiUrl}/delete-refresh-token`, {
            method: 'POST',
            redirect: 'follow',
            credentials: 'include',
            body: JSON.stringify({ jti: session.jti }),
        })
            .then((res) => {})
            .catch((err) => {
                return false
            })
    } catch (err) {
        return false
    }
}

function checkAdmin() {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return false
        }
        const role = window.localStorage.getItem('role')
        if (role) {
            return role === 'jurigo_admin' || role === 'jurigo_admin_on'
        }
    } catch (err) {
        return false
    }
    return false
}

function checkRestrictedAdmin() {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return false
        }
        const role = window.localStorage.getItem('role')
        if (role) {
            return role === 'jurigo_admin_restricted'
        }
    } catch (err) {
        return false
    }
    return false
}

function checkReportAdmin() {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return false
        }
        const role = window.localStorage.getItem('role')
        if (role) {
            return role === 'jurigo_report_admin'
        }
    } catch (err) {
        return false
    }
    return false
}

async function checkSessionAlive(tryToRefresh = false) {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return false
        }
        const exp = window.localStorage.getItem('expiry')

        if (exp) {
            // 1000 = 1 second
            // if the token has expired for less than 48 hours
            // we'll be able to refresh the session with the refresh token,
            // so return true
            const now = Date.now()
            const expiryPlus47Hours = exp + 47 * 3600 * 1000
            const expiryMinus5Seconds = exp - 5 * 1000
            if (now < expiryMinus5Seconds) {
                return true
            }
            if (now > expiryMinus5Seconds && expiryPlus47Hours > now) {
                // if session is about to expired (or about to in 5s) and
                // session has not been expired for more than 47 hours,
                // try to refresh
                if (tryToRefresh) {
                    const refreshed = await refresh()
                    if (refreshed) {
                        return refreshed
                    } else {
                        return false
                    }
                }
            }
        }
        return false
    } catch (err) {
        return false
    }
    return false
}

function getSession() {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return {}
        }
        if (window.localStorage.getItem('jti')) {
            return {
                isLoggedIn: true,
                user: window.localStorage.getItem('user'),
                role: window.localStorage.getItem('role'),
                token: window.localStorage.getItem('token'),
                id: window.localStorage.getItem('id'),
                jti: window.localStorage.getItem('jti'),
            }
        }
    } catch (err) {
        return {
            isLoggedIn: false,
            user: '',
            role: '',
            token: '',
        }
    }
    return {
        isLoggedIn: false,
        user: '',
        role: '',
        token: '',
    }
}

function refresh() {
    const currSession = getSession()
    return new Promise((resolve, reject) => {
        fetch(`//${apiUrl}/refresh-token`, {
            method: 'POST',
            redirect: 'follow',
            credentials: 'include',
            headers: {
                Authorization: currSession.token,
            },
            body: JSON.stringify({ jti: currSession.jti }),
        })
            .then((res) => {
                if (res.status === 200) {
                    res.json().then((json) => {
                        const newSession = saveSession(json.token)
                        resolve({
                            ...currSession,
                            ...newSession,
                        })
                    })
                } else {
                    deleteSession()
                    resolve(null)
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function sessionIsFresh() {
    try {
        if (typeof window === 'undefined' || !window.localStorage) {
            return false
        }
        const exp = window.localStorage.getItem('expiry')

        if (exp) {
            const now = Date.now()
            if (now < exp) {
                return true
            }
            console.log('false now is bigger than expiry')
        }
        return false
    } catch (err) {
        return false
    }
    return false
}

export default {
    saveSession,
    deleteSession,
    checkAdmin,
    checkSessionAlive,
    getSession,
    checkReportAdmin,
    checkRestrictedAdmin,
    isFresh: sessionIsFresh,
}

export const refreshSession = refresh
