const realestateVice = {
    label1: {
        fieldType: 'label',
        label: 'Votre vice-caché',
    },
    buySell: {
        label: 'Acheteur ou vendeur?',
        fieldType: 'buttonRow',
        options: [
            {
                value: 'buyer',
                label: 'Acheteur',
            },
            {
                value: 'seller',
                label: 'Vendeur',
            },
        ],
        preFormOnly: true,
    },
    propertyType: {
        label: 'Le bien visé correspond à quelle catégorie',
        fieldType: 'select',
        options: [
            {
                name: 'realestate',
                label: 'Immobilier (terrain, bâtisse, chalet, maison mobile)',
            },
            {
                name: 'mobile',
                label: 'Mobilier (bâteau, roulotte, VR, voiture, moto, équipements, meubles divers, etc)',
            },
            {
                name: 'other',
                label: 'Autre/Je ne sais pas',
            },
        ],
        preFormOnly: true,
    },
    otherPropertyType: {
        label: 'Préciser le bien',
        dependsOn: {
            fieldName: 'propertyType',
            toBe: ['mobile', 'other'],
        },
        preFormOnly: true,
    },
    buyerWants: {
        label: 'Vous souhaitez',
        fieldType: 'select',
        options: [
            {
                name: 'cancel',
                label: 'L’annulation de la vente',
            },
            {
                name: 'reduce',
                label: 'La réduction du prix de vente (dédommagement pour les travaux de réparation)',
            },
            {
                name: 'other',
                label: 'Autre / Je ne sais pas',
            },
        ],
        dependsOn: {
            fieldName: 'buySell',
            toBe: 'buyer',
        },
        preFormOnly: true,
    },
    sellerWants: {
        label: 'L’acheteur recherche',
        fieldType: 'select',
        options: [
            {
                name: 'cancel',
                label: 'L’annulation de la vente',
            },
            {
                name: 'reduce',
                label: 'La réduction du prix de vente (dédommagement pour les travaux de réparation)',
            },
            {
                name: 'other',
                label: 'Autre / Je ne sais pas',
            },
        ],
        dependsOn: {
            fieldName: 'buySell',
            toBe: 'seller',
        },
        preFormOnly: true,
    },
    otherWants: {
        label: 'Précisez ce que vous recherchez',
        dependsOnOne: [
            {
                fieldName: 'sellerWants',
                toBe: 'other',
            },
            {
                fieldName: 'buyerWants',
                toBe: 'other',
            },
        ],
        preFormOnly: true,
    },
    letter: {
        label: 'Avez-vous reçu ou transmis un avis de connaissance du vice, mise en demeure ou procédure dans ce dossier?',
        fieldType: 'yesNoDontKnow',
        postFormOnly: true,
    },
    value: {
        label: 'Quelle est la valeur du vice (montant que vous réclamez ou que l’on vous réclame)?',
        postFormOnly: true,
    },
    expert: {
        label: 'Avez-vous mandaté un expert pour le dossier (évaluateur, entrepreneur, ingénieur, inspecteur etc)',
        fieldType: 'yesNo',
        postFormOnly: true,
    },
    otherExpert: {
        label: `Précisez le type d'expert mandaté`,
        dependsOn: {
            fieldName: 'expert',
            toBe: 'yes',
        },
        postFormOnly: true,
    },
    amicable: {
        label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
        fieldType: 'yesNoDontKnow',
        postFormOnly: true,
    },
    otherDetails: {
        label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants?',
        postFormOnly: true,
    },
}

export default realestateVice
