/*
a field should have the following properties
{
  value: string, (required)
  valid: bool, (required)
  regex: regex string, (required)
  optional: bool (optional)
}
*/
export const validateForm = (self) => {
    const formElements = self.state.fields
        ? self.state.fields
        : self.props.fields
    const validatedFields = {}
    const isValidForm = Object.keys(formElements).reduce(
        (previous, current) => {
            const element = formElements[current]
            if (typeof element !== 'object') return previous && true // In this state, only fields are objects
            if (validateField(element.value, element.regex, element.optional)) {
                let valid = true
                if (element.fn) {
                    if (element.fn.call(self)) {
                        valid = true
                    } else {
                        valid = false
                    }
                }
                validatedFields[current] = {
                    ...element,
                    valid,
                }

                return previous && valid
            } else {
                validatedFields[current] = {
                    ...element,
                    valid: false,
                }

                return false
            }
        },
        true
    )

    return {
        valid: isValidForm,
        fields: validatedFields,
    }
}

export const validateField = (value, regex, opt) => {
    let optional = opt
    if (optional && !regex) {
        return true
    }
    if (regex) {
        return regex.test(value)
    }
    return (
        (Array.isArray(value) && (value.length > 0 || optional)) ||
        (!Array.isArray(value) && value && value !== '' && value !== '0')
    )
}
