import React from 'react'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

moment.locale('fr')

export default ({ name, label, value, onChange }) => <MuiPickersUtilsProvider utils={MomentUtils}>
    <DatePicker
        variant="outlined"
        label={label}
        name={name}
        format="DD MMM YYYY"
        value={value}
        onChange={onChange}
    />
</MuiPickersUtilsProvider>