export const regionsON = [
    {
        name: 'ON-GTA',
        label: 'Toronto and GTA',
    },
    {
        name: 'ON-OTTAWA',
        label: `Ottawa and surrounding (Cornwall, L'Orignal, etc)`,
    },
    {
        name: 'ON-NORTH',
        label: 'North (Sudbury, Thunder Bay, North Bay, Sault Ste. Marie, etc)',
    },
    {
        name: 'ON-EAST',
        label: 'East (Kingston, Frontenac, Peterborough, Belleville, etc)',
    },
    {
        name: 'ON-SOUTH',
        label: 'Central (Simcoe county)',
    },
    {
        name: 'ON-WEST',
        label: 'West (Guelph, Hamilton, Kitchener, London, Windsor, etc)',
    },
]
const regions = [
    {
        name: 'MTL',
        label: 'Montréal',
    },
    {
        name: 'LAV',
        label: 'Laval',
    },
    {
        name: 'CNA',
        label: 'Québec / Capitale-Nationale',
    },
    {
        name: 'AT',
        label: 'Abitibi-Témiscamingue',
    },
    {
        name: 'BSL',
        label: 'Bas-Saint-Laurent',
    },
    {
        name: 'CQ',
        label: 'Drummondville / Victoriaville / Centre du Québec ',
    },
    {
        name: 'CA',
        label: 'Chaudière-Appalaches',
    },
    {
        name: 'CN',
        label: 'Côte-Nord',
    },
    {
        name: 'ES',
        label: 'Estrie',
    },
    {
        name: 'GM',
        label: 'Gaspésie-îles-de-la-Madeleine',
    },
    {
        name: 'LAN',
        label: 'Lanaudière',
    },
    {
        name: 'LAU',
        label: 'Laurentides',
    },
    {
        name: 'MAU',
        label: 'Mauricie',
    },
    {
        name: 'MON',
        label: 'Montérégie',
    },
    {
        name: 'NOR',
        label: 'Nord du Québec',
    },
    {
        name: 'OUT',
        label: 'Outaouais',
    },
    {
        name: 'SAG',
        label: 'Saguenay-Lac-Saint-Jean',
    },
]

export default regions

export const regionsMappings = (function () {
    const mappings = {
        ON: "Toute l'Ontario",
        QC: 'Tout le Québec',
    }
    regions.forEach((type) => (mappings[type.name] = type.label))
    regionsON.forEach((type) => (mappings[type.name] = type.label))

    return mappings
})()

export const invertedRegionsMappings = (function () {
    const mappings = {
        "Toute l'Ontario": 'ON',
        'Tout le Québec': 'QC',
    }
    regions.forEach((type) => (mappings[type.label] = type.name))
    return mappings
})()
