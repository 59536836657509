import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'


const Selector = ({ label, name, value, options, onChange }) => {
    return <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
            labelId={`${name}-label`}
            label={label}
            id={name}
            value={value ? value : ''}
            onChange={(evt) => onChange(evt.target.value)}
        >
            {options.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                    <Trans>{item.label}</Trans>
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

export default Selector
