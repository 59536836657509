export default {
    0: {
        value: 0,
        label: '',
    },
    8: {
        value: 8,
        label: 'Moins de 41 999$',
        min: 0,
        max: 41999,
    },
    9: {
        value: 9,
        label: 'Entre 42 000$ et 50 999$',
        min: 42000,
        max: 50999,
    },
    1: {
        value: 1,
        label: 'Moins de 51 000$',
        deprecated: true,
    },
    2: {
        value: 2,
        label: 'Entre 51 000$ et 64 999$',
        min: 51000,
        max: 64999,
    },
    3: {
        value: 3,
        label: 'Entre 65 000$ et 84 999$',
        min: 65000,
        max: 84999,
    },
    4: {
        value: 4,
        label: 'Entre 85 000$ et 99 999$',
        min: 85000,
        max: 99999,
    },
    5: {
        value: 5,
        label: 'Entre 100 000$ et 149 999$',
        min: 100000,
        max: 149999,
    },
    6: {
        value: 6,
        label: 'Entre 150 000$ et 300 000$',
        min: 150000,
        max: 300000,
    },
    7: {
        value: 7,
        label: 'Plus de 300 000$',
        min: 300001,
        max: 999999999,
    },
}

export const revenueRangesOrderedKeys = [0, 8, 9, 1, 2, 3, 4, 5, 6, 7]
