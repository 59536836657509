import React from 'react'
import ButtonRow from './index'

export default ({ saveFieldState, value, name }) => (
    <>
        <ButtonRow
            value={value}
            name={name}
            saveFieldState={saveFieldState}
            options={[
                { value: 'yes', label: 'Oui' },
                { value: 'no', label: 'Non' },
                { value: 'dontKnow', label: 'Je ne sais pas' },
            ]}
        />
    </>
)
