import React from 'react'
import { Button, ButtonGroup } from '@material-ui/core'
import { Trans } from 'gatsby-plugin-react-i18next'

export default ({ value, name, options, saveFieldState }) => {
    return (
        <ButtonGroup
            variant="contained"
            size="large"
            fullWidth={true}
            color="primary">
            {options.map((o) => (
                <Button
                    key={o.value}
                    color={value === o.value ? 'secondary' : 'primary'}
                    onClick={() => saveFieldState(name, o.value, true)}>
                    <Trans>{o.label}</Trans>
                </Button>
            ))}
        </ButtonGroup>
    )
}
